import { Route, Routes } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "react-query";
import PayForm from "./pages/PayForm";
import NotFound from "./pages/error/404";

function App() {
    const queryClient = new QueryClient()
    return (
        <QueryClientProvider client={queryClient}>
            <Routes>
                <Route path="/" element={<PayForm />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </QueryClientProvider>
    );
}

export default App;
