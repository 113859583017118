import { FormPropsInterface } from "./typings";

export function Form({loading,className, onSubmit, children}:FormPropsInterface) {
    return (
        <form className={className} onSubmit={onSubmit}>
            <fieldset disabled={loading}>
                {children}
            </fieldset>
        </form>
    );
}
