import SimpleButton from "./SimpleButton";
import MDIcons from "../icons/MDIcons";
import BoxIcons from "../icons/BoxIcons";
import { ButtonPropsInterface } from "./typings";


export function AddButton({value = 'Créer', onClick}:ButtonPropsInterface) {
    return (<SimpleButton type={"button"} className={"primary rounded flex items-center"}
                          onClick={onClick}><MDIcons icon={"plus"} className={"mr-2"}/> {value}
    </SimpleButton>);
}

export function SubmitButton({value = 'Enregistrer', loading = false, onClick,className=''}:ButtonPropsInterface) {
    return <SimpleButton onClick={onClick} className={className? className : "primary rounded ml-2"}
                         type="submit" disabled={loading === true && 'disabled'}>
        {loading === true &&
            <BoxIcons icon={"loader"} className={"animate-spin font-size-16 align-middle mr-2"}/>} {value}
    </SimpleButton>
}

export function CancelButton({value = 'Annuler', onClick}:ButtonPropsInterface) {
    return (<SimpleButton type={"reset"} className={"secondary rounded"} onClick={onClick}> {value} </SimpleButton>);
}

export function EditButton({value = 'Modifier', onClick}:ButtonPropsInterface) {
    return (<SimpleButton className="bg-primary rounded bg-opacity-50 hover:bg-opacity-100 flex items-center"
                          onClick={onClick}><MDIcons icon={"pencil"} outline={true} className={"mr-2"}/> {value}
    </SimpleButton>);
}

export function DeleteButton({value = 'Supprimer', loading = false, onClick}:ButtonPropsInterface) {
    return (<SimpleButton
        className="bg-red-500 bg-opacity-40 hover:bg-opacity-100 text-white rounded bg-opacity-20 flex items-center justify-center"
        onClick={onClick}>{loading === true ?
        <BoxIcons icon={"loader"} className={"animate-spin font-size-16 align-middle me-2"}/> :
        <MDIcons icon={"delete"} outline={true} className={"mr-2"}/>} {value} </SimpleButton>);
}

export function LinkButton({children, onClick, disabled = false, active = false, className}:ButtonPropsInterface) {
    return (<SimpleButton
        className={`text-center text-sm font-normal transition-colors hover:bg-blue-500 hover:text-white rounded-lg ${className} ${active ? "primary cursor-not-allowed" : ""} ${disabled ? "cursor-not-allowed" : ""}`}
        onClick={onClick}> {children} </SimpleButton>);
}
