import { useMutation, useQueryClient } from "react-query";
import request from "./axios";
import { toast } from "react-toastify";
import { RequestType } from "./typings";


export const useMutate = (url: string, type: RequestType = "post") => {
    return useMutation((payloads?: any) => request(url, type, payloads), {
        onError: () => {
            toast.error("Une erreur es survenue, nous travaillons pour un rétablissement à la normale au plus vite");
        }
    });
}

export const useMutateWithInvalidateQueries = (url: string, type: RequestType = "post", queryKey: (string | number)[]) => {
    const queryClient = useQueryClient();
    return useMutation((payloads) => request(url, type, payloads), {
        onSuccess: () => {
            return queryClient.invalidateQueries(queryKey);
        },
        onError: (error: any) => {
            toast.error(error.response.data.message)
        },
    });
}

export const useMutateWithSetQueriesData = (url: string, type: RequestType = "post", queryKey: (string | number)[]) => {
    const queryClient = useQueryClient();
    return useMutation((payloads) => request(url, type, payloads), {
        onSuccess: (data: any) => {
            return queryClient.setQueryData(queryKey, data);
        },
        onError: (error: any) => {
            toast.error(error.response.data.message)
        }
    });
}