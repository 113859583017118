import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { removeSessionUser } from "./storage";
import { AUTH_SIGN_IN_URL } from "./api";
import { RequestType } from "./typings";

const expiredSessionAction = (reason: any) => {
    if (reason?.response?.status === 403) {
        toast.warning('Votre session a expiré, vous allez être déconnectés');
        setTimeout(() => {
            removeSessionUser()
            window.location.replace(AUTH_SIGN_IN_URL);
        }, 3000)
    } else {
        if (reason?.response?.status === 401) {
            removeSessionUser();
            window.location.replace(AUTH_SIGN_IN_URL);
        } else {
            if (reason?.response?.status === 500) {
                toast.error("Une erreur est survenue, nous travaillons pour un rétablissement à la normale au plus vite");
            }
        }
    }
};


const request = (url: string, type: RequestType, payloads: any | undefined) => {
    //if (sessionExpired) {
    let config: AxiosRequestConfig<any> = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    switch (type) {
        case "delete":
            return axios.delete(url, config);
        case 'get':
            return axios.get(url, config).catch(expiredSessionAction);
        case 'patch':
            return axios.patch(url, payloads, config).catch(expiredSessionAction);
        case 'put':
            return axios.put(url, payloads, config).catch(expiredSessionAction);
        default:
            return axios.post(url, payloads, config).catch(expiredSessionAction);
    }
    /* } else {
        return null;
    } */
}
export default request;
