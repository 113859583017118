import { Form } from "../components/form/Form";
import { InputEmail, InputNumber, InputText } from "../components/form/Input";
import { useForm } from "react-hook-form";
import { SubmitButton } from "../components/button/CustomButton";
import { useMutate } from "../utils/mutation";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import ReactDOM from "react-dom";
import "./form.scss"
import { toast } from "react-toastify";

function PayForm() {
    const [html, setHtml] = useState("");
    const [searchParams] = useSearchParams();
    const ref = searchParams.get("ref");
    const datas = window.atob(ref + "").split(":");
    const apiKey = datas[0];
    const serviceKey = datas[1];
    const paymentRef = datas[2];
    const amount = datas[3];
    const company = datas[4];
    const description = datas[5];
    const firstName = datas[6];
    const lastName = datas[7];

    const {
        register, handleSubmit, formState: {
            errors
        }
    } = useForm({
        defaultValues: {
            first_name: firstName,
            last_name: lastName,
            email: "",
            number: "",
            amount: amount,
        }
    });

    const { mutateAsync: pay, isLoading } = useMutate("https://ms-payment.nexah.net/api/v1/place-deposit-uba")

    const handleSubmitForm = (data: any) => {
        pay({
            ...data,
            api_key: apiKey,
            service_key: serviceKey,
            payment_ref: paymentRef,
            description: description
        }).then((r: any) => {
            if (r && typeof r.data == "string") {
                setHtml(r.data)
            } else {
                toast.error(r ? r.data.message : "Une erreur s'est produite, veuillez réessayer plus tard!")
            }
        })
    }
    useEffect(() => {
        if (html !== "") {
            document.getElementById("clickButton")?.click();
        }
    }, [html])

    return (
        html ?
            (ReactDOM.createPortal(<div dangerouslySetInnerHTML={{ __html: html }}></div>, document.body)) : (
                <div>
                    <div className="checkout">
                        <div className="credit-card-box">
                            <div className="flip">
                                <div className="front">
                                    <div className="chip"></div>
                                    <div className="logo">
                                        <svg version="1.1" id="visa" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                            width="47.834px" height="47.834px" viewBox="0 0 47.834 47.834" >
                                            <g>
                                                <g>
                                                    <path d="M44.688,16.814h-3.004c-0.933,0-1.627,0.254-2.037,1.184l-5.773,13.074h4.083c0,0,0.666-1.758,0.817-2.143
                         c0.447,0,4.414,0.006,4.979,0.006c0.116,0.498,0.474,2.137,0.474,2.137h3.607L44.688,16.814z M39.893,26.01
                         c0.32-0.819,1.549-3.987,1.549-3.987c-0.021,0.039,0.317-0.825,0.518-1.362l0.262,1.23c0,0,0.745,3.406,0.901,4.119H39.893z
                         M34.146,26.404c-0.028,2.963-2.684,4.875-6.771,4.875c-1.743-0.018-3.422-0.361-4.332-0.76l0.547-3.193l0.501,0.228
                         c1.277,0.532,2.104,0.747,3.661,0.747c1.117,0,2.313-0.438,2.325-1.393c0.007-0.625-0.501-1.07-2.016-1.77
                         c-1.476-0.683-3.43-1.827-3.405-3.876c0.021-2.773,2.729-4.708,6.571-4.708c1.506,0,2.713,0.31,3.483,0.599l-0.526,3.092
                         l-0.351-0.165c-0.716-0.288-1.638-0.566-2.91-0.546c-1.522,0-2.228,0.634-2.228,1.227c-0.008,0.668,0.824,1.108,2.184,1.77
                         C33.126,23.546,34.163,24.783,34.146,26.404z M0,16.962l0.05-0.286h6.028c0.813,0.031,1.468,0.29,1.694,1.159l1.311,6.304
                         C7.795,20.842,4.691,18.099,0,16.962z M17.581,16.812l-6.123,14.239l-4.114,0.007L3.862,19.161
                         c2.503,1.602,4.635,4.144,5.386,5.914l0.406,1.469l3.808-9.729L17.581,16.812L17.581,16.812z M19.153,16.8h3.89L20.61,31.066
                         h-3.888L19.153,16.8z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="number">xxxx xxxx xxxx xxxx</div>
                                    <div className="card-holder">
                                        <label>Card holder</label>
                                        <div>xxxx</div>
                                    </div>
                                    <div className="card-expiration-date">
                                        <label>Expires</label>
                                        <div>xx/xx</div>
                                    </div>
                                </div>
                                <div className="back">
                                    <div className="strip"></div>
                                    <div className="logo">
                                        <svg version="1.1" id="visa" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                            width="47.834px" height="47.834px" viewBox="0 0 47.834 47.834" >
                                            <g>
                                                <g>
                                                    <path d="M44.688,16.814h-3.004c-0.933,0-1.627,0.254-2.037,1.184l-5.773,13.074h4.083c0,0,0.666-1.758,0.817-2.143
                         c0.447,0,4.414,0.006,4.979,0.006c0.116,0.498,0.474,2.137,0.474,2.137h3.607L44.688,16.814z M39.893,26.01
                         c0.32-0.819,1.549-3.987,1.549-3.987c-0.021,0.039,0.317-0.825,0.518-1.362l0.262,1.23c0,0,0.745,3.406,0.901,4.119H39.893z
                         M34.146,26.404c-0.028,2.963-2.684,4.875-6.771,4.875c-1.743-0.018-3.422-0.361-4.332-0.76l0.547-3.193l0.501,0.228
                         c1.277,0.532,2.104,0.747,3.661,0.747c1.117,0,2.313-0.438,2.325-1.393c0.007-0.625-0.501-1.07-2.016-1.77
                         c-1.476-0.683-3.43-1.827-3.405-3.876c0.021-2.773,2.729-4.708,6.571-4.708c1.506,0,2.713,0.31,3.483,0.599l-0.526,3.092
                         l-0.351-0.165c-0.716-0.288-1.638-0.566-2.91-0.546c-1.522,0-2.228,0.634-2.228,1.227c-0.008,0.668,0.824,1.108,2.184,1.77
                         C33.126,23.546,34.163,24.783,34.146,26.404z M0,16.962l0.05-0.286h6.028c0.813,0.031,1.468,0.29,1.694,1.159l1.311,6.304
                         C7.795,20.842,4.691,18.099,0,16.962z M17.581,16.812l-6.123,14.239l-4.114,0.007L3.862,19.161
                         c2.503,1.602,4.635,4.144,5.386,5.914l0.406,1.469l3.808-9.729L17.581,16.812L17.581,16.812z M19.153,16.8h3.89L20.61,31.066
                         h-3.888L19.153,16.8z"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </div>
                                    <div className="ccv">
                                        <label>CCV</label>
                                        <div>XXX</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Form loading={isLoading} onSubmit={handleSubmit(handleSubmitForm)} className="form" >

                            {company ? <p className="mb-4 text-right"><span>Entreprise</span>  <h1 className="text-xl font-semibold ">{company}</h1> </p> : (<>
                                <div>
                                    <InputText register={register} label={"Nom"} name={"first_name"}
                                        labelClassName={"form-label inline-block"}
                                        placeholder={"Entrer votre  nom"} error={errors?.first_name} />
                                </div>
                                <div>
                                    <div>
                                        <InputText register={register} label={"Prenom"} name={"last_name"}
                                            labelClassName={"form-label inline-block"}
                                            placeholder={"Entrer votre prénom"} error={errors?.last_name} />
                                    </div>
                                </div>
                            </>)

                            }
                            <div className="grid gap-4">
                                <div>
                                    <InputEmail placeholder={"Entrer votre email"} label={"Email"}
                                        labelClassName={"form-label inline-block"} register={register}
                                        name={"email"} error={errors?.email} />
                                </div>
                                <div>
                                    <InputText register={register} label={"Téléphone"} name={"number"}
                                        placeholder={"Entrer votre numéro de téléphone"}
                                        error={errors?.number} />
                                </div>
                            </div>
                            {!company ? <InputNumber label={'Montant'} name={'amount'} register={register}
                                placeholder={"Entrer le montant"} error={errors?.amount} /> : <div className="mt-4 text-center"><p>Total à payer</p> <span className="text-3xl font-bold"><span> {amount} XAF</span></span></div>

                            }
                            {/*<div className="text-xl font-semibold flex justify-center items-center p-4 "><div>Total {searchParams.get("amount")} XAF</div></div>*/}
                            <SubmitButton loading={isLoading} value={"Payer"} className="w-full mt-4 primary h-10 rounded" />
                        </Form>
                    </div>


                </div>)


    )

}

export default PayForm