import { LangType } from "../config/global";

const auth_key = "auth";
const lang_key = "lang";

export const addSessionUser = (user: any) => {
    localStorage.setItem(auth_key, JSON.stringify(user))
}

export const getSessionUser = () => {
    return localStorage.getItem(auth_key) !== "undefined" && localStorage.getItem(auth_key) !== null ? JSON.parse(localStorage.getItem(auth_key) ?? "") : null
}

export const removeSessionUser = () => {
    localStorage.removeItem(auth_key)
}

export const getSessionLang = (): LangType => {
    if (localStorage.getItem(lang_key)) {
        return localStorage.getItem(lang_key) === LangType.fr ? LangType.fr : LangType.en;
    } else {
        return LangType.fr;
    }
}

export const setSessionLang = (layout: LangType) => {
    localStorage.setItem(lang_key, layout)
}