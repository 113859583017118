import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getSessionLang } from "../utils/storage";
import resourcesToBackend from 'i18next-resources-to-backend';

export const defaultNS = getSessionLang();

i18n.use(initReactI18next)
.use(resourcesToBackend((language: string, namespace: string) => import(`../locales/${language}/${namespace}.json`)))
.init({
  lng: getSessionLang(),
  fallbackLng: 'fr',
  ns: ["menu","page"],
});

export default i18n;